import Section from "../core/Section";
import Banner, { SubLink } from "../core/Banner";
import tryoutsImage from "../resources/tryouts/tryouts_25-26_14-16-18.png";
import { Link } from "react-router-dom";

const SUB_LINKS: SubLink[] = [
  {
    title: "2025-2026 Tryouts",
    subPath: "featured",
  },
  // { title: "Our Principles", subPath: "principles" },
];

export default function Home() {
  return (
    <>
      <Banner
        title="Long Island Mustangs"
        headline="Welcome to the home of the Mustangs!"
        subLinks={SUB_LINKS}
      />
      <Section subPath="featured" title="2025-2026 Tryouts">
        <p className="text-xl">
          Register{" "}
          <Link className="font-black" to="/tryouts">
            HERE
          </Link>
          !
        </p>
        <div className="grid items-center grid-cols-1 w-full xl:w-3/5 2xl:w-2/5">
          <img
            src={tryoutsImage}
            className="rounded-3xl p-4 object-contain w-full"
            alt="2025-2026 Tryouts - 8U, 10U & 12U"
          />
        </div>
      </Section>
      {/* <Section subPath="principles" title="Our Principles">
        <HomePrinciples />
      </Section> */}
    </>
  );
}
