import React, { useState } from "react";
import Banner from "../core/Banner";
import Section from "../core/Section";
import { Form, Formik, FormikHelpers } from "formik";
import { JERSEY_SIZES, PHONE_REGEX } from "../util/General";
import FieldText from "../general/FieldText";
import FieldDropdown from "../general/FieldDropdown";
import Field from "../general/Field";
import Button from "../general/Button";
import API from "../util/API";
import * as Yup from "yup";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { tryoutAgeGroups } from "./TryoutsData";

dayjs.extend(duration);

const POSITIONS = ["Forward", "Defense", "Goalie"];

export default function Tryouts() {
  const [enrolled, setEnrolled] = useState(false);

  const PAGE_SECTIONS = {
    details: { subPath: "details", title: "Program Details" },
    dates: { subPath: "dates", title: "Tryout Dates & Details" },
    register: { subPath: "register", title: "Register" },
  };

  const defaultValues: TryoutsForm = {
    firstName: "",
    lastName: "",
    parentFirstName: "",
    parentLastName: "",
    email: "",
    phone: "",
    position: "",
    birthDate: "",
    jerseySize: "",
    streetAddress: "",
    cityAddress: "",
    stateAddress: "NY",
    zipAddress: "",
    isReturning: false,
    agreedPaymentAmount: 0,
  };

  const VALIDATION_SCHEME = Yup.object({
    firstName: Yup.string().min(2).required("Required"),
    lastName: Yup.string().min(2).required("Required"),
    parentFirstName: Yup.string().min(2).required("Required"),
    parentLastName: Yup.string().min(2).required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Invalid phone number")
      .required("Required"),
    position: Yup.string().oneOf(POSITIONS).required("Required"),
    birthDate: Yup.date()
      .min(new Date(Date.parse("2005-01-01")))
      .max(new Date(Date.parse("2020-01-01")))
      .required("Required"),
    jerseySize: Yup.string().oneOf(JERSEY_SIZES).required("Required"),
    streetAddress: Yup.string().min(2).required("Required"),
    cityAddress: Yup.string().min(2).required("Required"),
    stateAddress: Yup.string().min(2).required("Required"),
    zipAddress: Yup.string().min(2).required("Required"),
    // agreement: Yup.boolean()
    //     .isTrue("You must agree to sign up for the event")
    //     .required(),
  });

  return (
    <>
      <Banner
        title="2025-2026 Tryouts"
        headline="Tournament Bound Tier 3 Travel Hockey"
        subLinks={Object.values(PAGE_SECTIONS)}
      />
      <Section {...PAGE_SECTIONS.dates}>
        <div className="uppercase text-lg font-bold">Freeport Ice Rink</div>
        <div className="uppercase text-lg font-medium mb-8">
          130 E Merrick Rd, Freeport, NY 11520
        </div>

        <div className="grid items-center grid-rows-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 uppercase text-lg">
          {Object.values(tryoutAgeGroups).map((ageGroup) => (
            <div id={ageGroup.name}>
              <h3 className="text-lg font-semibold uppercase underline ">
                {ageGroup.name}
              </h3>
              {ageGroup.dates.map((date) => (
                <div>
                  {date.start.format("MMMM DD, YYYY @ h:mm A")} -{" "}
                  {(date.end ? date.end : date.start.add(1, "h")).format(
                    "h:mm A"
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Section>

      <Section {...PAGE_SECTIONS.details} invertColor={true}>
        <div className="space-y-4 w-1/2 uppercase text-lg">
          <ul className="space-y-2">
            <li>2 Weekly Practices</li>
            <li>6 Powerskating Sessions</li>
            <li>6 Skills Sessions</li>
            <li>6 Goaltender Training Sessions</li>
            <li>Off Ice Training & Video Analysis</li>
            <li>Mustangs play in the LIAHL</li>
          </ul>
        </div>
      </Section>

      <Section {...PAGE_SECTIONS.register}>
        <Formik
          initialValues={defaultValues}
          validationSchema={VALIDATION_SCHEME}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form className="px-2 w-full md:w-3/4 xl:w-1/2">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <FieldText
                  label="Player's First Name"
                  name="firstName"
                  placeholder="John"
                  type="text"
                />

                <FieldText
                  label="Player's Last Name"
                  name="lastName"
                  placeholder="Boston"
                  type="text"
                />

                <FieldText
                  label="Parent's First Name"
                  name="parentFirstName"
                  placeholder="Jill"
                  type="text"
                />

                <FieldText
                  label="Parent's Last Name"
                  name="parentLastName"
                  placeholder="Boston"
                  type="text"
                />

                <FieldText
                  label="Email"
                  name="email"
                  placeholder="jillboston@email.com"
                  type="email"
                />

                <FieldText
                  label="Phone"
                  name="phone"
                  placeholder="(631) 555-0825"
                  type="phone"
                />

                <FieldText
                  label="Street Address"
                  name="streetAddress"
                  placeholder="4470 Clinton Street"
                  type="text"
                />

                <FieldText
                  label="City"
                  name="cityAddress"
                  placeholder="Manorville"
                  type="text"
                />

                <FieldText
                  label="State"
                  name="stateAddress"
                  placeholder="NY"
                  type="text"
                />

                <FieldText
                  label="Zip Code"
                  name="zipAddress"
                  placeholder="11949"
                  type="text"
                />

                <FieldDropdown label="position" name="position">
                  {["", ...POSITIONS].map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </FieldDropdown>

                <FieldText
                  label="Birth Date"
                  name="birthDate"
                  placeholder="08/16/2006"
                  type="date"
                />

                <FieldDropdown label="Jersey Size" name="jerseySize">
                  {["", ...JERSEY_SIZES].map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </FieldDropdown>

                <div className="col-span-1 sm:col-span-2">
                  <Field
                    label=""
                    name="isReturning"
                    type="checkbox"
                    text="Are you a returning Long Island Mustangs player?"
                  />
                </div>

                {/* <div className="col-span-1 sm:col-span-2">
                                    <Field
                                        label=""
                                        name="agreement"
                                        type="checkbox"
                                        text={`I agree to pay the price of $${priceInCents(values) / 100
                                            }.00 to the Long Island Mustangs via Venmo @MustangsHockey prior to the above listed Tryout date.`}
                                    />
                                </div> */}

                <div className="col-span-1 sm:col-span-2">
                  {enrolled ? (
                    <div className="uppercase text-md font-bold">
                      {values.firstName} has been enrolled! Please view the
                      Tryout Dates & Details above for their age group.
                    </div>
                  ) : (
                    <Button
                      disabled={
                        values === defaultValues || !isValid || enrolled
                      }
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Enroll
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Section>
    </>
  );

  function priceInCents(tryoutForm: TryoutsForm) {
    const isGoalie = tryoutForm.position === "Goalie";
    // const birthDate = dayjs(tryoutForm.birthDate);
    // const is12U = birthDate.isAfter(tryoutAgeGroups["12U"].yearStart - 1, "year");

    if (isGoalie) {
      return 0;
    } else if (tryoutForm.isReturning) {
      return 5000;
    }
    return 10000;
  }

  async function onSubmit(
    values: TryoutsForm,
    { setSubmitting }: FormikHelpers<TryoutsForm>
  ) {
    values.agreedPaymentAmount = priceInCents(values) / 100;
    try {
      await API.post("tryouts", values);
      setEnrolled(true);
    } catch (error) {
      console.error(error);
      setEnrolled(false);
    }
    setSubmitting(false);
  }
}

interface TryoutsForm {
  firstName: string;
  lastName: string;
  parentFirstName: string;
  parentLastName: string;
  email: string;
  phone: string;
  position: string;
  birthDate: string;
  jerseySize: string;
  streetAddress: string;
  cityAddress: string;
  stateAddress: string;
  zipAddress: string;
  isReturning: boolean;
  agreedPaymentAmount: number;
}
