import dayjs from "dayjs";
import { DateSpan } from "../util/dayjs";

export const tryoutAgeGroups: { [key: string]: TryoutAgeGroup } = {
  // "8U": {
  //     yearStart: 2017,
  //     yearEnd: 2020,
  //     dates: [
  //         {
  //             start: dayjs("March 8, 2025 13:00:00"),
  //             end: dayjs("March 8, 2025 14:00:00"),
  //         }, {
  //             start: dayjs("March 22, 2025 13:00:00"),
  //             end: dayjs("March 22, 2025 14:00:00"),
  //         },
  //     ],
  //     name: "10U",
  // },
  // "10U": {
  //     yearStart: 2015,
  //     yearEnd: 2016,
  //     dates: [
  //         {
  //             start: dayjs("March 8, 2025 13:00:00"),
  //             end: dayjs("March 8, 2025 14:00:00"),
  //         }, {
  //             start: dayjs("March 22, 2025 13:00:00"),
  //             end: dayjs("March 22, 2025 14:00:00"),
  //         },
  //     ],
  //     name: "10U",
  // },
  // "12U": {
  //     yearStart: 2013,
  //     yearEnd: 2014,
  //     dates: [
  //         {
  //             start: dayjs("March 8, 2025 14:00:00"),
  //             end: dayjs("March 8, 2025 15:00:00"),
  //         }, {
  //             start: dayjs("March 22, 2025 14:00:00"),
  //             end: dayjs("March 22, 2025 15:00:00"),
  //         },
  //     ],
  //     name: "12U",
  // },
  "14U": {
    yearStart: 2011,
    yearEnd: 2012,
    dates: [
      {
        start: dayjs("April 12, 2025 13:00:00"),
      },
      {
        start: dayjs("April 19, 2025 13:00:00"),
      },
    ],
    name: "14U",
  },
  "16U": {
    yearStart: 2009,
    yearEnd: 2010,
    dates: [
      {
        start: dayjs("April 12, 2025 14:00:00"),
      },
      {
        start: dayjs("April 13, 2025 20:30:00"),
      },
      {
        start: dayjs("April 19, 2025 20:30:00"),
      },
    ],
    name: "16U",
  },
  "18U": {
    yearStart: 2007,
    yearEnd: 2008,
    dates: [
      {
        start: dayjs("April 12, 2025 20:30:00"),
      },
      {
        start: dayjs("April 13, 2025 20:30:00"),
      },
      {
        start: dayjs("April 19, 2025 20:30:00"),
      },
    ],
    name: "18U",
  },
};

interface TryoutAgeGroup {
  yearStart: number;
  yearEnd: number;
  dates: DateSpan[];
  name: string;
}
